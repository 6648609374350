<script lang="tsx">
import { PropType } from "vue"
import { INavBarLinkData } from "@/types/components/common/navbar"
import { IClickType } from "@/constant/index"
import CommonArrow from "@/assets/icons/common/arrow.svg"
import { NuxtLink } from "#components"
import CommonTransition from "@/components/common/Transition.vue"

export default defineComponent({
  name: "",
  props: {
    navList: {
      type: Array as PropType<INavBarLinkData>,
      default: () => {
        return []
      }
    }
  },
  emits: ["linkItemClick"],
  setup(props) {
    let resultNavList = ref<INavBarLinkData>([])

    const addNodeKey = (list: INavBarLinkData) => {
      list.map((item) => {
        item._switchFlag = false
        if (item.children && item.children.length) {
          addNodeKey(item.children)
        }
      })
    }

    watch(
      () => props.navList,
      (newValue) => {
        resultNavList.value = useCloneDeep(newValue)
        addNodeKey(resultNavList.value)
      },
      {
        immediate: true
      }
    )

    let renderIndex = 0

    return {
      renderIndex,
      resultNavList
    }
  },
  methods: {
    linkItemClick(item) {
      this.$emit("linkItemClick", item)
    },
    showChildClick(item) {
      item._switchFlag = !item._switchFlag
    },
    renderChildList(navList) {
      if (navList && navList.length) {
        return (
          <div class={["navbar-menu-list-con"]}>
            {navList.map((item) => {
              return (
                <div class="list-link-item">
                  {item.type === IClickType.Skip && (
                    <NuxtLink
                      to={item.path}
                      onClick={() => {
                        this.linkItemClick(item)
                      }}
                    >
                      {item.name}
                    </NuxtLink>
                  )}
                  {item.type === IClickType.Click && (
                    <span
                      onClick={() => {
                        this.linkItemClick(item)
                      }}
                    >
                      {item.name}
                    </span>
                  )}
                  {item.type === IClickType.Hover && item.children && item.children.length && (
                    <div class="list-link-item-child">
                      <div
                        class="child-title"
                        onClick={() => {
                          this.showChildClick(item)
                        }}
                      >
                        <span>{item.name}</span>
                        <CommonArrow class={["arrow", item._switchFlag ? "active" : ""]} />
                      </div>
                      <CommonTransition>{item._switchFlag && this.renderChildList(item.children)}</CommonTransition>
                    </div>
                  )}
                </div>
              )
            })}
          </div>
        )
      } else {
        return null
      }
    }
  },
  render() {
    return <div class="navbar-menu-list">{this.renderChildList(this.resultNavList)}</div>
  }
})
</script>

<style scoped lang="scss">
.navbar-menu-list {
  display: flex;
  flex-direction: column;
  .arrow {
    color: #000;
    transition: transform 0.3s ease-in-out;
    &.active {
      transform: rotate((180deg));
    }
  }
  .navbar-menu-list-con {
    display: flex;
    flex-direction: column;
    .list-link-item {
      font-weight: 510;
      font-size: 20px;
      line-height: 24px;
      line-height: 40px;
      cursor: pointer;
      a {
        color: #000;
      }
      .list-link-item-child {
        .child-title {
          display: flex;
          align-items: center;
        }
        .navbar-menu-list-con {
          padding-left: 32px;
          .list-link-item {
            font-weight: 400;
            display: flex;
          }
        }
      }
    }
  }
}
</style>
