<script lang="tsx">
import { PropType } from "vue"
import { INavBarLinkData } from "@/types/components/common/navbar"
import { IClickType } from "@/constant/index"
import CommonArrow from "@/assets/icons/common/arrow.svg"
import { ClientOnly, NuxtLink } from "#components"

export default defineComponent({
  name: "",
  props: {
    navList: {
      type: Array as PropType<INavBarLinkData>,
      default: () => {
        return []
      }
    }
  },
  emits: ["linkItemClick"],
  setup() {
    return {}
  },
  methods: {
    linkItemClick(item) {
      this.$refs.dropdownRef?.handleClose()
      this.$emit("linkItemClick", item)
    },
    renderChildList(list) {
      return (
        <div class="navbar-menu-list-container">
          {list.map((item, i) => {
            return (
              <div class="list-link-item" key={i}>
                {item.type === IClickType.Skip && (
                  <NuxtLink
                    to={item.path}
                    onClick={() => {
                      this.linkItemClick(item)
                    }}
                  >
                    {item.name}
                  </NuxtLink>
                )}
                {item.type === IClickType.Click && (
                  <>
                    <span
                      onClick={() => {
                        this.linkItemClick(item)
                      }}
                    >
                      {item.name}
                    </span>
                    {item.slotName && this.$slots[item.slotName](item)}
                  </>
                )}
                {item.type === IClickType.Hover && item.children && item.children.length && (
                  <el-dropdown popper-class="link-drop-down_zz" showTimeout={100} ref="dropdownRef" trigger="hover">
                    {{
                      default: () => {
                        return (
                          <span class="el-dropdown-link">
                            {item.name}
                            <CommonArrow />
                          </span>
                        )
                      },
                      dropdown: () => {
                        return <ClientOnly>{this.renderChildList(item.children)}</ClientOnly>
                      }
                    }}
                  </el-dropdown>
                )}
              </div>
            )
          })}
        </div>
      )
    }
  },
  render() {
    return <div class="navbar-menu-list-warpper">{this.renderChildList(this.navList)}</div>
  }
})
</script>

<style scoped lang="scss">
.navbar-menu-list-warpper {
  .navbar-menu-list-container {
    display: flex;
    align-items: center;
    .list-link-item {
      margin-right: 32px;
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;
      display: flex;
      align-items: center;
      line-height: 21px;
      color: #000;
      position: relative;
      a {
        color: #000;
      }
      :deep(.el-dropdown) {
        line-height: 21px;
        display: flex;
        .el-dropdown-link {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 400;
          outline: 0;
          color: #000;
          svg {
            transition: transform linear 0.2s;
          }
          &:hover {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.el-popper.el-dropdown__popper.link-drop-down_zz {
  border: none;
  background: #ffffff;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  .el-popper__arrow {
    display: none;
  }
  .navbar-menu-list-container {
    padding: 16px;
    .list-link-item {
      color: #000;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      display: flex;
      border-radius: 8px;
      transition: all 0.2s linear;
      cursor: pointer;
      &:hover {
        background: $bg-color;
      }
      a,
      span {
        color: #000;
        padding: 12px 16px;
      }
    }
  }
}
</style>
